import styled from 'styled-components'
import { Card } from '@pancakeswap/uikit'

export const BodyWrapper = styled(Card)`
  border-radius: 24px;
  max-width: 536px;
  width: 100%;
  z-index: 1;
  overflow:visible;
  border-radius:15px;padding: 30px 20px 40px;
  box-shadow:inset 0px 20px 36px rgba(51, 51, 51, 0.39);  
  border-radius: 31px;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.06);
  backdrop-filter: blur(10px);
  --webkit-backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.01);

  &:before{
    position: absolute;
    content: "";
    left: 10px;
    right: -10px;
    bottom: -10px;
    top: 10px;
    box-shadow:inset 0px 20px 36px rgba(51, 51, 51, 0.39); 
    border-radius: 31px;
    z-index: -1;
    border: 1px solid rgba(255, 255, 255, 0.06);
    opacity: 0.64;
    backdrop-filter: blur(10px);
    --webkit-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.03);
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}
